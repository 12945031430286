// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-content-template-page-js": () => import("../src/pages/content-template-page.js" /* webpackChunkName: "component---src-pages-content-template-page-js" */),
  "component---src-pages-company-content-template-page-js": () => import("../src/pages/company-content-template-page.js" /* webpackChunkName: "component---src-pages-company-content-template-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-details-js": () => import("../src/pages/app/details.js" /* webpackChunkName: "component---src-pages-app-details-js" */),
  "component---src-pages-app-results-js": () => import("../src/pages/app/results.js" /* webpackChunkName: "component---src-pages-app-results-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-de-js": () => import("../src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-de-spiel-die-beliebtesten-gesellschaftsspiele-der-welt-js": () => import("../src/pages/de/spiel/die-beliebtesten-gesellschaftsspiele-der-welt.js" /* webpackChunkName: "component---src-pages-de-spiel-die-beliebtesten-gesellschaftsspiele-der-welt-js" */),
  "component---src-pages-de-spielzeuge-die-erfolgreichsten-spielzeuge-der-welt-js": () => import("../src/pages/de/spielzeuge/die-erfolgreichsten-spielzeuge-der-welt.js" /* webpackChunkName: "component---src-pages-de-spielzeuge-die-erfolgreichsten-spielzeuge-der-welt-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pr-die-beliebtesten-gesellschaftsspiele-der-welt-js": () => import("../src/pages/pr/die-beliebtesten-gesellschaftsspiele-der-welt.js" /* webpackChunkName: "component---src-pages-pr-die-beliebtesten-gesellschaftsspiele-der-welt-js" */),
  "component---src-pages-pr-die-erfolgreichsten-spielzeuge-der-welt-js": () => import("../src/pages/pr/die-erfolgreichsten-spielzeuge-der-welt.js" /* webpackChunkName: "component---src-pages-pr-die-erfolgreichsten-spielzeuge-der-welt-js" */)
}

