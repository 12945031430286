module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Better Play","short_name":"tbp","start_url":"/","background_color":"#2a079b","theme_color":"#2a079b","display":"minimal-ui","icon":"static/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MXZ7F4G","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-106940289-1","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://cfec07a58fc64becba4d7eed4cce269a@sentry.io/1501815","environment":"production","tags":{"git_commit":"56383bf"},"release":"56383bfd39b0dac52c974e5b82ee987108e13bae","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito:300,400,700:latin"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
